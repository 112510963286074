exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#001529",
	"menuHover": "#000c17",
	"menuLightBg": "#fff",
	"menuLightHover": "#f0f1f5",
	"subMenuBg": "#000c17",
	"subMenuHover": "#001528",
	"sideBarWidth": "256px",
	"sidebarTitle": "#fff",
	"sidebarLightTitle": "#001529"
};